@use "src/styles/color";
@use "src/styles/breakpoint";
@use "src/styles/fontweight";

.errorPage {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // The height should be the viewport (100vh) - the header (60px).
  height: calc(100vh - 60px);
  flex-grow: 1;
  background-color: color.$white;
  @media (min-width: breakpoint.$md) {
    background-color: color.$grey-10;
  }

  .errorPageContainer {
    background-color: transparent;
    width: 100%;
    padding: 20px;
    margin: auto;
    @media (min-width: breakpoint.$md) {
      padding: 50px 25px;
    }
    @media (min-width: breakpoint.$xl) {
      max-width: 1140px;
    }
  }

  .errorPageRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
    @media (min-width: breakpoint.$md) {
      margin-right: -25px;
      margin-left: -25px;
    }
  }

  .errorPageCol {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: breakpoint.$md) {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
      padding-right: 25px;
      padding-left: 25px;
    }

    h1 {
      font-size: 1.875rem;
      margin-top: 0;
      margin-bottom: 1.875rem;
      font-weight: fontweight.$semi_bold;
      line-height: 1.4;
      @media (min-width: breakpoint.$md) {
        font-size: 3rem;
      }
    }

    p {
      color: rgba(color.$black, 0.6) !important;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .statusCode {
      color: rgba(color.$black, 0.5) !important;
      margin-top: -0.625rem;
      margin-bottom: 1rem;

      code {
        color: inherit;
      }
    }
  }

  .textCenter {
    text-align: center;
  }

  .link {
    &:hover {
      text-decoration: none;
    }
  }

  .buttonMargin {
    margin: 1.875rem 0;
  }
}
