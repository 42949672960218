@use 'src/styles/color';
@use 'src/styles/breakpoint';

.card {
  background: color.$white;
  border-radius: 15px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 1.875rem;

  &.removeOnMobile {
    border-radius: 0;
    border: none;
    padding: 0;
    @media (min-width: breakpoint.$md) {
      border-radius: 15px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      padding: 1.875rem;
    }
  }
}
